<template>
  <div class="centerBot">
    <dv-borderBox12 class="left-top-borderBox12">
      <div class="left-top">
        <div class="module-title">
          <i class="iconfont">&#xe78f;</i>
          <span>资源概览</span>
        </div>

        <div
          style="display: flex;align-items:center;justify-content:space-around;margin-top:1rem;"
        >
          <div style="height:14rem;width:13rem;">
            <div class="module-title">资源名称：{{ sourceData.name }}</div>
            <div class="module-title">资源来源：{{ sourceData.from }}</div>
          </div>
          <div id="pingjia" style="height:16rem;width:21rem;"></div>
          <div id="manyi" style="height:16rem;width:16rem;">
            <div>
              <div class="module-title">
                <span style="font-size:14px">基础标签</span>
              </div>
              <div style="display: flex;flex-wrap: wrap;">
                <div
                  v-for="item in sourceData.baseTagData"
                  :key="item"
                  :style="{ backgroundColor: RoundColor() }"
                  class="tag-style"
                >
                  {{ item }}
                </div>
              </div>
            </div>
            <div>
              <div class="module-title">
                <span style="font-size:14px">能力标签</span>
              </div>
              <div style="display: flex;flex-wrap: wrap;">
                <div
                  v-for="item in sourceData.canTagData"
                  :key="item"
                  :style="{ backgroundColor: RoundColor() }"
                  class="tag-style"
                >
                  {{ item }}
                </div>
              </div>
            </div>
            <div>
              <div class="module-title">
                <span style="font-size:14px">服务标签</span>
              </div>
              <div style="display: flex;flex-wrap: wrap;">
                <div
                  class="tag-style"
                  v-for="item in sourceData.ServiceTagData"
                  :key="item"
                  :style="{ backgroundColor: RoundColor() }"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="module-title">
          <i class="iconfont">&#xe78f;</i>
          <span>资源应用情况</span>
        </div>
        <div style="display: flex;justify-content: space-around;">
          <div id="linepo" style="height:24rem;width:50rem"></div>
        </div>
      </div>
    </dv-borderBox12>
  </div>
</template>

<script>
import * as echarts from "echarts"
export default {
  data() {
    return {
      xdata: ["1月", "2月", "3月", "4月", "5月"],
      source: [
        {
          name: "支持性托管",
          from: "立德",
          indicator: [
            {
              text: "专业性",
              max: 100,
            },
            {
              text: "合规性",
              max: 100,
            },
            {
              text: "覆盖率",
              max: 100,
            },
            {
              text: "资源链接能力",
              max: 100,
            },
            {
              text: "服务评价",
              max: 100,
            },
          ],
          rote: [90, 90, 80, 80, 85],
          baseTagData: ["社会工作", "第一批社工服务机构", "民办非企业"],
          canTagData: ["10个区县", "100+服务项目", "北京市优秀社工团队"],
          ServiceTagData: [
            "残障增能",
            "社区助老",
            "社区社会组织服务",
            "社会工作者能力培训",
          ],

          ydata1: [35, 20, 38, 42, 20],
        },
        {
          name: "就业资源",
          from: "FESCO",
          indicator: [
            {
              text: "专业性",
              max: 100,
            },
            {
              text: "合规性",
              max: 100,
            },
            {
              text: "丰富性",
              max: 100,
            },
            {
              text: "覆盖率",
              max: 100,
            },
            {
              text: "满意度",
              max: 100,
            },
          ],
          rote: [95, 100, 90, 98, 95],
          baseTagData: ["社会工作", "第一批社工服务机构", "民办非企业"],
          canTagData: ["10个区县", "100+服务项目", "北京市优秀社工团队"],
          ServiceTagData: [
            "残障增能",
            "社区助老",
            "社区社会组织服务",
            "社会工作者能力培训",
          ],
          ydata1: [420, 200, 100, 250, 220],
        },
        {
          name: "培训资源",
          from: "智选学校",
          indicator: [
            {
              text: "专业性",
              max: 100,
            },
            {
              text: "合规性",
              max: 100,
            },
            {
              text: "满意度",
              max: 100,
            },
            {
              text: "就业率",
              max: 100,
            },
            {
              text: "覆盖率",
              max: 100,
            },
          ],
          rote: [95, 100, 95, 87, 80],
          baseTagData: ["民办非企业", "职业技能", "残疾人", "特殊教育"],
          canTagData: [
            "培训合格率97.23%",
            "培训就业率86.84%",
            "北京市残疾人培训基地",
            "200+课程清单",
            "服务20000+",
          ],
          ServiceTagData: ["培就一体", "非遗技能", "职业素养", "就业技能"],
          ydata1: [200, 120, 260, 300, 350],
        },
        {
          name: "康复资源",
          from: "中康",
          indicator: [
            {
              text: "专科声誉",
              max: 100,
            },
            {
              text: "康复质量",
              max: 100,
            },
            {
              text: "康复环境",
              max: 100,
            },
            {
              text: "医院规模",
              max: 100,
            },
            {
              text: "医疗设备",
              max: 100,
            },
          ],
          rote: [95, 98, 95, 90, 90],
          baseTagData: ["事业单位", "三甲医院", "中西医结合", "中国残联"],
          canTagData: ["规模最大", "覆盖全国", "体育赛事保障", "康复医学院"],
          ServiceTagData: [
            "残疾预防",
            "康复临床",
            "脊髓损伤康复",
            "儿童康复",
            "心理治疗",
          ],
          ydata1: [100, 60, 145, 200, 200],
        },
        {
          name: "社会组织",
          from: "狮子会",
          indicator: [
            {
              text: "专业性",
              max: 100,
            },
            {
              text: "公益性",
              max: 100,
            },
            {
              text: "组织规模",
              max: 100,
            },
            {
              text: "影响力",
              max: 100,
            },
            {
              text: "活跃度",
              max: 100,
            },
          ],
          rote: [95, 95, 95, 95, 98],
          baseTagData: ["世界最大", "公益慈善", "NGO"],
          canTagData: [
            "209个国家和地区",
            "48000个分会",
            "150万个会员",
            "涉及领域众多",
          ],
          ServiceTagData: ["狮子会国际基金会", "青少狮会", "助残"],
          ydata1: [25, 65, 35, 80, 100],
        },
        {
          name: "党建资源",
          from: "燕东集团党委",
          indicator: [
            {
              text: "组织规模",
              max: 100,
            },
            {
              text: "活跃度",
              max: 100,
            },
            {
              text: "创新性",
              max: 100,
            },
            {
              text: "活动丰富性",
              max: 100,
            },
            {
              text: "经费投入",
              max: 100,
            },
          ],
          rote: [95, 95, 95, 95, 95],
          baseTagData: ["国有企业", "集成电路", "高精尖产业"],
          canTagData: ["芯片", "高科技", "资源广泛", "科技助残"],
          ServiceTagData: ["党员教育", "残健融合", "扶贫"],
          ydata1: [45, 60, 40, 35, 55],
        },
      ],
      sourceData: {},
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  mounted() {
    this.sourceData = this.source[0]
    this.sourcePingjiaOptions(this.sourceData)
    this.getlineOptions(this.sourceData)
    this.startInterval()
  },
  methods: {
    startInterval() {
      const _self = this
      // 应通过接口获取配置时间，暂时写死5s
      const time = 3000
      if (this.intervalId !== null) {
        clearInterval(this.intervalId)
      }
      this.intervalId = setInterval(() => {
        _self.reSelectRandom()
      }, time)
    },
    reSelectRandom() {
      var rand = Math.round(Math.random() * 5)
      this.$nextTick(() => {
        this.sourceData = this.source[rand]
        this.sourcePingjiaOptions(this.source[rand])
        this.getlineOptions(this.source[rand])
      })
    },

    RoundColor() {
      return (
        "rgb(" +
        [
          Math.round(Math.random() * 200 + 55),
          Math.round(Math.random() * 200 + 55),
          Math.round(Math.random() * 200 + 55),
        ].join(",") +
        ")"
      )
    },

    sourcePingjiaOptions(sourceInData) {
      // console.log(this.RoundColor())
      if (document.getElementById("pingjia") == null) {
        return
      }
      var getichartDom
      var getiChart
      if (getichartDom == null && getiChart == null) {
        getichartDom = document.getElementById("pingjia")
        getiChart = echarts.init(getichartDom)
      }
      // var legendData = ["个体数据", "平均数据"] //图例
      var indicator = sourceInData.indicator
      var dataArr = [
        {
          value: sourceInData.rote,
          // name: legendData[0],
          itemStyle: {
            normal: {
              lineStyle: {
                color: "#4A99FF",
                // shadowColor: '#4A99FF',
                // shadowBlur: 10,
              },
              shadowColor: "#4A99FF",
              shadowBlur: 5,
            },
          },
          areaStyle: {
            normal: {
              // 单项区域填充样式
              color: {
                type: "linear",
                x: 0, //右
                y: 0, //下
                x2: 1, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0,
                    color: "#4A99FF",
                  },
                  {
                    offset: 0.5,
                    color: "rgba(0,0,0,0)",
                  },
                  {
                    offset: 1,
                    color: "#4A99FF",
                  },
                ],
                globalCoord: false,
              },
              opacity: 1, // 区域透明度
            },
          },
        },
      ]
      var colorArr = ["#4A99FF"] //颜色
      var option = {
        title: {
          show: true,
          text: "资源评价",
          left: "40%",

          textStyle: {
            color: "#bcdcff",
            fontSize: "14",
            // fontWeight:400,
          },
          // subtextStyle: {
          //   color: "#90979c",
          //   fontSize: "16",
          // },
        },
        color: colorArr,
        legend: {
          show: false,
        },
        radar: {
          // shape: 'circle',
          center: ["50%", "60%"],
          name: {
            textStyle: {
              color: "#fff",
              fontSize: 12,
              padding: 0,
            },
          },
          indicator: indicator,
          splitArea: {
            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            show: true,
            areaStyle: {
              // 分隔区域的样式设置。
              color: ["rgba(255,255,255,0)", "rgba(255,255,255,0)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
            },
          },
          axisLine: {
            //指向外圈文本的分隔线样式
            lineStyle: {
              color: "#153269",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#113865", // 分隔线颜色
              width: 1, // 分隔线线宽
            },
          },
        },
        series: [
          {
            type: "radar",
            symbolSize: 8,
            data: dataArr,
          },
        ],
      }
      getiChart.setOption(option)
      window.addEventListener("resize", function() {
        getiChart.resize() //下面可以接多个图
      })
    },

    getlineOptions(sourceInData) {
      if (document.getElementById("linepo") == null) {
        return
      }
      var linepochartDom
      var linepoChart
      if (linepochartDom == null && linepoChart == null) {
        linepochartDom = document.getElementById("linepo")
        linepoChart = echarts.init(linepochartDom)
      }

      var charts = {
        unit: "",
        // names: ["劳动产品出品量", "出勤时间"],
        lineX: this.xdata,
        value: [sourceInData.ydata1],
      }
      var color = ["rgba(93, 255, 255"]
      var lineY = []

      for (var i = 0; i < 1; i++) {
        var x = i
        if (x > color.length - 1) {
          x = color.length - 1
        }
        var data = {
          // name: charts.names[i],
          type: "line",
          color: color[x] + ")",
          // yAxisIndex: i,
          smooth: false,
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: color[x] + ", 0.3)",
                  },
                  {
                    offset: 0.8,
                    color: color[x] + ", 0)",
                  },
                ],
                false
              ),
              shadowColor: "rgba(0, 0, 0, 0.1)",
              shadowBlur: 10,
            },
          },
          symbol: "circle",
          symbolSize: 5,
          data: charts.value[i],
        }
        lineY.push(data)
      }

      var option = {
        title: {},
        tooltip: {
          trigger: "axis",
        },

        legend: {
          show: false,
          top: "2%",
          // data: charts.names,
          textStyle: {
            fontSize: 14,
            color: "F1F1F3",
          },
          right: "4%",
        },
        grid: {
          top: "10%",
          left: "4%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          show: true,
          type: "category",
          boundaryGap: false,
          data: charts.lineX,
          axisLabel: {
            textStyle: {
              color: "rgb(0,253,255,0.6)",
            },
            // formatter: function(params) {
            //   return params.split(" ")[0] + "\n" + params.split(" ")[1]
            // },
          },
        },
        yAxis: {
          show: true,
          splitArea: {
            show: true,
            areaStyle: {
              color: "rgba(1, 22, 53, 1)",
            },
          },
          // name: "劳动产品出品量",
          type: "value",
          axisLabel: {
            formatter: "{value}",
            textStyle: {
              color: "rgb(0,253,255,0.6)",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgb(23,255,243,0.3)",
            },
          },

          axisLine: {
            show: true,
            lineStyle: {
              color: "rgb(0,253,255,0.6)",
            },
          },
        },
        series: lineY,
      }
      linepoChart.setOption(option)
      window.addEventListener("resize", function() {
        linepoChart.resize() //下面可以接多个图
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.module-title {
  padding: 10px 10px;
  color: #bcdcff;
  font-size: 1rem;
  font-weight: bold;
  .iconfont {
    font-size: 0.875rem;
    margin-right: 0.625rem;
    color: #89e5ff;
    font-weight: 400;
  }
}
.centerBot {
  position: relative;
  height: 47.5rem;
  width: 57.625rem;
  display: flex;
  margin-top: 3rem;
  // margin-right: 2rem;
  .left-top-borderBox12 {
    width: inherit;
    height: inherit;
    padding: 10px;
    .left-top {
      padding: 1rem;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: rgba(13, 28, 83, 0.3);
      .title-dis {
        margin-top: 0.9375;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 1rem;
        color: #c0c9d2;
        &-keyword {
          padding-left: 0.625rem;
          color: #47dae8;
        }
      }
    }
  }
}

.tag-style {
  padding: 0.1rem 0.2rem;
  margin: 0.1rem;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
}
</style>
