<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center" style="height:2.5vh">
          <dv-decoration-10
            class="dv-dec-10"
            @click.native="pageSevenClick()"
          />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="['#568aea', '#000000']" />
            <div class="title">
              <span class="title-text" @click="pageClick()">
                <img
                  src="@/assets/logo.png"
                  width="40px"
                  alt=""
                  style="margin-right:10px;"
                />
                国华众联助残机构数智运营SaaS服务平台</span
              >
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="['#568aea', '#000000']"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>
        <div>
          <div class="pagecontent" style="height:51rem">
            <LeftPage />

            <CenterPage v-if="LableValue === 0" className="center-page" />
            <ServiceTag v-if="LableValue === 1" className="center-page" />
            <PolicyRules v-if="LableValue === 2" className="center-page" />
            <PlanCreate v-if="LableValue === 3" className="center-page" />
            <ResouceEntry v-if="LableValue === 4" className="center-page" />
            <FlowPath v-if="LableValue === 5" className="center-page" />
            <SalesTarget v-if="LableValue === 6" className="center-page" />
            <BeiJing v-if="LableValue === 7" className="center-page" />

            <RightPage @beijingShow="centerBeijing" />
          </div>
          <div class="bottom-label">
            <div
              v-for="(item, index) in bottomList"
              :class="
                LableValue === item.value
                  ? 'detail-list-active'
                  : 'detail-list-item'
              "
              :key="index"
              @click="handlerLableClick(item.value)"
            >
              <h3 style="margin-top: 1rem;margin-left: 0.625rem;">
                {{ item.label }}
              </h3>
              <div class="detail-item-text">
                <img
                  :src="require(`../assets/left/tag${index + 1}.png`)"
                  :alt="item.label"
                />
                <div style="margin-left:0.625rem">
                  <h3>
                    <i class="iconfont" style="margin-right:0.625rem"
                      >&#xe7f7;</i
                    >{{ item.descOne }}
                  </h3>
                  <h3>
                    <i class="iconfont" style="margin-right:0.625rem"
                      >&#xe7f7;</i
                    >{{ item.descTwo }}
                  </h3>
                  <h3>
                    <i class="iconfont" style="margin-right:0.625rem"
                      >&#xe7f7;</i
                    >{{ item.descThree }}
                  </h3>
                </div>
                <!-- <span>{{ item.number }}</span>
                  <span class="unit">{{ item.unit }}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin"
// import { formatTime } from "../utils/index.js"
import LeftPage from "../lwcomponents/leftPage/left-content.vue"

import CenterPage from "../lwcomponents/centerPage/index.vue"
import ServiceTag from "../lwcomponents/centerPage/service-tag.vue"
import PolicyRules from "../lwcomponents/centerPage/policy-rules.vue"
import PlanCreate from "../lwcomponents/centerPage/plan-create.vue"
import ResouceEntry from "../lwcomponents/centerPage/resouce-entry.vue"
import FlowPath from "../lwcomponents/centerPage/flow-path.vue"

import BeiJing from "../lwcomponents/centerPage/beijing.vue"
import SalesTarget from "../lwcomponents/centerPage/sales-target.vue"

import RightPage from "../lwcomponents/rightPage/index.vue"

import "swiper/css/swiper.css"

export default {
  components: {
    LeftPage,
    CenterPage,
    RightPage,
    ServiceTag,
    PolicyRules,
    PlanCreate,
    BeiJing,
    ResouceEntry,
    SalesTarget,
    FlowPath,
  },
  mixins: [drawMixin],
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 10000,
          // stopOnLastSlide: false,
          // disableOnInteraction: false,
        },
      },
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      isPageShow: 1,
      bottomList: [
        {
          label: "服务标签",
          value: 1,
          descOne: "数据来源安全可信",
          descTwo: "科学完整的标签体系",
          descThree: "实时动态残疾人标签",
        },
        {
          label: "政策规则",
          value: 2,
          descOne: "帮扶政策拆解规则化",
          descTwo: "服务经验知识库打造",
          descThree: "助残行业知识图谱生成",
        },
        {
          label: "方案生成",
          value: 3,
          descOne: "机器学习推荐算法",
          descTwo: "精准服务方案生成",
          descThree: "直接关联活动组织",
        },
        {
          label: "资源入驻",
          value: 4,
          descOne: "外部资源整合审核",
          descTwo: "资源标签化具像化",
          descThree: "资源标签迭代完善",
        },
        {
          label: "流程优化",
          value: 5,
          descOne: "服务流程信息化自动化",
          descTwo: "基于科技提升服务体验",
          descThree: "降低机构运营成本",
        },
      ],
      LableValue: 0,
    }
  },

  // directives: {
  //   swiper: directive,
  // },
  // computed: {
  //   swiperOne() {
  //     return this.$refs.mySwiper.swiper
  //   },
  // },
  mounted() {
    // this.timeFn()
    // this.pageShow()
    this.cancelLoading()
    // this.startInterval()
  },
  // beforeDestroy() {
  //   // clearInterval(this.timing)
  //   clearInterval(this.intervalId)
  // },
  methods: {
    // startInterval() {
    //   const _self = this
    //   const time = 15000
    //   if (this.intervalId !== null) {
    //     clearInterval(this.intervalId)
    //   }
    //   this.intervalId = setInterval(() => {
    //     _self.reSelectRandom()
    //   }, time)
    // },
    // reSelectRandom() {
    //   var rand = Math.round(Math.random() * 7)
    //   this.$nextTick(() => {
    //     this.LableValue = rand
    //   })
    // },
    // timeFn() {
    //   this.timing = setInterval(() => {
    //     this.dateDay = formatTime(new Date(), "HH: mm: ss")
    //     this.dateYear = formatTime(new Date(), "yyyy-MM-dd")
    //     this.dateWeek = this.weekday[new Date().getDay()]
    //   }, 1000)
    // },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    handlerLableClick(value) {
      this.LableValue = value
    },
    pageClick() {
      this.LableValue = 0
    },
    pageSevenClick() {
      this.LableValue = 7
    },
    centerBeijing(value) {
      this.LableValue = value
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index.scss";

.bg {
  background-image: url("../assets/pageBg.png");
}
.bottom-label {
  height: 12rem;
  // margin-top:1.5rem;
  // border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.swiper-container {
  width: 100%;

  .swiper-slide {
    width: 100%;
  }
}
.indexPage {
  position: relative;
  overflow: hidden;
  margin: 0px;
  padding: 10px 0 0 0;
}
.pagecontent {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  .center-page {
    flex: 1;
  }
}

.detail-list-active {
  display: flex;
  flex-direction: column;
  // height: 80%;
  padding: 0 0.625rem;
  width: 18%;
  border-radius: 5px;
  border: 1px solid #343f4b;
  background-color: rgba(19, 25, 47, 0.8);
  box-shadow: rgb(101, 134, 236) 0px 0px 10px inset;
}

.detail-list {
  &-item {
    display: flex;
    flex-direction: column;
    // height: 80%;
    padding: 0 0.625rem;
    width: 18%;
    border-radius: 5px;
    border: 1px solid #343f4b;
    background-color: rgba(19, 25, 47, 0.8);
    &:hover {
      box-shadow: rgb(101, 134, 236) 0px 0px 10px inset;
    }
  }
}
.detail-item-text {
  margin-left: 0.625rem;
  margin-top: 1rem;
  display: flex;
  img {
    width: 6.25rem;
    height: 6.25rem;
  }
  h3 {
    color: #bcdcff;
    font-size: 16px;
    margin-bottom: 1.25rem;
  }
  span {
    font-weight: 500px;
    font-size: 1.25rem;
    font-weight: bolder;
    background: linear-gradient(to bottom, #fff, #4db6e5);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
  .unit {
    font-size: 1rem;
    margin-left: 0.625rem;
  }
}
// {
//    touch-action: none;
// }
</style>
