<template>
  <div class="centerBot">
    <dv-borderBox12 class="left-top-borderBox12">
      <div class="left-top">
        <div class="module-title">
          <i class="iconfont">&#xe78f;</i>
          <span>学员信息</span>
        </div>
        <div class="centerBottom">
          <div class="detail-list">
            <div class="detail-list-item">
              <div
                class="detail-item-text"
                v-if="Object.keys(nameData).length !== 0"
              >
                <h3>姓名:{{ nameData.person.name }}</h3>
                <h3>性别:{{ nameData.person.sex }}</h3>
                <h3>年龄:{{ nameData.person.age }}</h3>
                <h3>残疾类型:{{ nameData.person.disable_type }}</h3>
                <h3>残疾等级:{{ nameData.person.disable_level }}</h3>
              </div>
            </div>
          </div>
        </div>

        <div style="display: flex;justify-content: space-around;">
          <div id="active" style="height:15rem;width:14rem;">
            <dv-border-box-12
              :color="['#4D67B7', '#34D3EB']"
              backgroundColor="#12182D"
            >
              <div class="module-content">
                <div>人员标签</div>
                <div
                  style="display: flex;flex-wrap: wrap;margin-top:5px;margin-left:10px"
                >
                  <div
                    v-for="item in nameData.person_label"
                    :key="item"
                    :style="{ backgroundColor: RoundColor() }"
                    class="tag-style"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </dv-border-box-12>
          </div>
          <div id="renshu" style="height:15rem;width:23rem;">
            <dv-border-box-12
              :color="['#4D67B7', '#34D3EB']"
              backgroundColor="#12182D"
            >
              <div class="module-content">
                <div>政策规则</div>
                <div style="margin-top:5px">
                  <div v-for="item in nameData.rules" :key="item.name">
                    <div style="font-size:14px">{{ item.name }}</div>
                    <div style="display: flex;flex-wrap: wrap;margin-left:10px">
                      <div
                        v-for="mand in item.demand"
                        :key="mand"
                        class="tag-style"
                        :style="{ backgroundColor: RoundColor() }"
                      >
                        {{ mand }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </dv-border-box-12>
          </div>
          <div id="biaoqian" style="height:15rem;width:14rem;">
            <dv-border-box-12
              :color="['#4D67B7', '#34D3EB']"
              backgroundColor="#12182D"
            >
              <div class="module-content">
                <div>资源列表</div>
                <div style="margin-top:5px">
                  <div v-for="item in nameData.resource" :key="item.name">
                    <div style="font-size:14px;margin-left:5px;">
                      {{ item.name }}
                    </div>
                    <div style="display: flex;flex-wrap: wrap;margin-left:10px">
                      <div
                        v-for="mand in item.demand"
                        :key="mand"
                        class="tag-style"
                        :style="{ backgroundColor: RoundColor() }"
                      >
                        {{ mand }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </dv-border-box-12>
          </div>
        </div>
        <div style="display: flex;justify-content: space-around;">
          <div style="height:8rem;width:17rem;"></div>
          <div
            style="height:10rem;width:17rem;display: flex;
    justify-content: center;align-items: center;"
          >
            <div
              style="width:9rem;height:9rem;border: 2px solid #4D67B7;
                border-radius: 50%;display: flex;
                justify-content: center;
                align-items: center;
                color: #bcdcff;
                font-size:1rem;
                flex-direction: column;"
            >
              机器学习
              <div>决策生成</div>
            </div>
          </div>
          <div style="height:8rem;width:17rem;"></div>
        </div>

        <div style="display: flex;justify-content: space-around;height:12.4rem">
          <el-table :data="nameData.service" border style="width: 100%">
            <!-- <el-table-column
              align="center"
              label="序号"
              width="65"
              type="index"
            > -->
            <!-- </el-table-column> -->
            <el-table-column align="center" min-width="40px" label="服务类型">
              <template slot-scope="{ row }">
                <span>{{ row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="符合政策">
              <template slot-scope="{ row }">
                <span>{{ row.zhengce }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" min-width="40px" label="推荐资源">
              <template slot-scope="{ row }">
                <span>{{ row.source1 }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" min-width="40px" label="备选资源">
              <template slot-scope="{ row }">
                <span>{{ row.source2 }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" min-width="60px" label="建议活动">
              <template slot-scope="{ row }">
                <span>{{ row.active }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </dv-borderBox12>
  </div>
</template>

<script>
// import * as echarts from "echarts"
export default {
  data() {
    return {
      nameDataList: [
        {
          person: {
            name: "彭*易",
            sex: "女",
            age: 19,
            level: "中专",
            disable_type: "智力残疾",
            disable_level: "二级",
          },
          person_label: [
            "女",
            "中专",
            "二级",
            "阅读",
            "绘画",
            "16-25岁",
            "智力残疾",
            "月坛街道",
            "唱歌",
          ],
          rules: [
            {
              name: "《基本康复目录(2019年)》",
              demand: ["本市户籍", "年龄在16周岁及以上", "智力残疾人"],
            },
            {
              name: "《就业保障金征收使用管理办法》",
              demand: [
                "就业年龄段内",
                "签订1年以上（含1年）劳动合同",
                "实际支付的工资不低于北京市最低工资标准",
                "足额缴纳社会保险费",
              ],
            },
            {
              name: "《职业康复劳动项目基本条件》",
              demand: ["本市户籍", "就业年龄段内"],
            },
          ],
          resource: [
            {
              name: "康复资源",
              demand: ["中康", "英智"],
            },
            {
              name: "培训资源",
              demand: ["智选职康站", "西城区职康站"],
            },
            {
              name: "就业资源",
              demand: ["FESCO", "招聘企业"],
            },
          ],
          service: [
            {
              name: "康复",
              zhengce: "《基本康复目录(2019年)》",
              source1: "中康",
              source2: "北康",
              active: "社会生活能力的行为训练",
            },
            {
              name: "劳动就业",
              zhengce: "《就业保障金征收使用管理办法》",
              source1: "FESCO",
              source2: "爱心企业",
              active: "就业岗位推荐",
            },
            {
              name: "职业康复",
              zhengce: "《职业康复劳动项目基本条件》",
              source1: "智选职康站",
              source2: "西城区职康站",
              active: "职业康复劳动项目",
            },
          ],
        },
        {
          person: {
            name: "郭*燕",
            sex: "女",
            age: 39,
            level: "大专",
            disable_type: "精神残疾",
            disable_level: "二级",
          },
          person_label: [
            "女",
            "大专",
            "二级",
            "摄影",
            "手工制作",
            "36-40岁",
            "精神残疾",
            "牛街街道",
            "唱歌",
          ],
          rules: [
            {
              name: "《基本康复目录(2019年)》",
              demand: ["本市户籍", "年龄在16周岁及以上", "精神残疾人"],
            },
            {
              name: "《职业技能提升培训实施细则》",
              demand: ["持证残疾人", "本市户籍", "就业年龄段内"],
            },
            {
              name: "《残疾人持卡免费进入景区的通告》",
              demand: ["持证残疾人", "市属公园"],
            },
          ],
          resource: [
            {
              name: "康复资源",
              demand: ["中康", "英智"],
            },
            {
              name: "培训资源",
              demand: ["智选培训学校", "非遗工作室"],
            },
            {
              name: "就业资源",
              demand: ["智选职康站", "西城区职康站"],
            },
          ],
          service: [
            {
              name: "康复",
              zhengce: "《基本康复目录（2019年）》",
              source1: "中康",
              source2: "北康",
              active: "回归社会技能训练",
            },
            {
              name: "劳动就业",
              zhengce: "《职业技能提升培训实施细则》",
              source1: "智选培训学校",
              source2: "非遗工作室",
              active: "景泰蓝制作培训",
            },
            {
              name: "文化休闲",
              zhengce: "《残疾人持卡免费进入景区的通告》",
              source1: "玉渊潭公园",
              source2: "香山公园",
              active: "“迎冬奥，健步走”活动",
            },
          ],
        },
        {
          person: {
            name: "赵*",
            sex: "男",
            age: 40,
            level: "本科",
            disable_type: "肢体残疾",
            disable_level: "一级",
          },
          person_label: [
            "男",
            "肢体残疾",
            "本科",
            "计算机",
            "36-40岁",
            "一级",
            "新华街道",
            "唱歌",
          ],
          rules: [
            {
              name: "《居家环境无障碍改造服务管理办法》",
              demand: ["持证残疾人", "本市户籍", "未实施过居家改造的残疾人"],
            },
            {
              name: "《职业技能提升培训实施细则》",
              demand: ["持证残疾人", "本市户籍", "就业年龄段内"],
            },
            {
              name: "《就业保障金征收使用管理办法》",
              demand: [
                "就业年龄段内",
                "签订1年以上（含1年）劳动合同",
                "实际支付的工资不低于北京市最低工资标准",
                "足额缴纳社会保险费",
              ],
            },
          ],
          resource: [
            {
              name: "无障碍改造",
              demand: ["残联", "居家无障碍改造机构"],
            },
            {
              name: "培训资源",
              demand: ["智选职业技能培训学校", "技能培训学校"],
            },
            {
              name: "就业资源",
              demand: ["国华众联", "互联网企业"],
            },
          ],
          service: [
            {
              name: "无障碍",
              zhengce: "《居家环境无障碍改造服务管理办法》",
              source1: "中康",
              source2: "北康",
              active: "回归社会技能训练",
            },
            {
              name: "劳动就业",
              zhengce: "《职业技能提升培训实施细则》",
              source1: "智选培训学校",
              source2: "非遗工作室",
              active: "景泰蓝制作培训",
            },
            {
              name: "劳动就业",
              zhengce: "《就业保障金征收使用管理办法》",
              source1: "国华众联",
              source2: "互联网企业",
              active: "就业岗位推荐",
            },
          ],
        },
      ],

      incomeList: [
        { payment_date: "222" },
        { payment_date: "222" },
        { payment_date: "222" },
      ],
      nameData: {},
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  mounted() {
    this.nameData = this.nameDataList[0]
    this.startInterval()
  },
  methods: {
    RoundColor() {
      return (
        "rgb(" +
        [
          Math.round(Math.random() * 200 + 55),
          Math.round(Math.random() * 200 + 55),
          Math.round(Math.random() * 200 + 55),
        ].join(",") +
        ")"
      )
    },
    startInterval() {
      const _self = this
      // 应通过接口获取配置时间，暂时写死5s
      const time = 3000
      if (this.intervalId !== null) {
        clearInterval(this.intervalId)
      }
      this.intervalId = setInterval(() => {
        _self.reSelectRandom()
      }, time)
    },
    reSelectRandom() {
      var rand = Math.round(Math.random() * 2)
      this.$nextTick(() => {
        this.nameData = this.nameDataList[rand]
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.module-title {
  padding: 10px 10px;
  color: #bcdcff;
  font-size: 1rem;
  font-weight: bold;
  .iconfont {
    font-size: 0.875rem;
    margin-right: 0.625rem;
    color: #89e5ff;
    font-weight: 400;
  }
}
.module-content {
  padding: 12px 12px;
  color: #bcdcff;
  font-size: 1rem;
  .iconfont {
    font-size: 0.875rem;
    margin-right: 0.625rem;
    color: #89e5ff;
    font-weight: 400;
  }
}
.centerBot {
  position: relative;
  height: 47.5rem;
  width: 57.625rem;
  display: flex;
  margin-top: 3rem;
  // margin-right: 2rem;
  .left-top-borderBox12 {
    width: inherit;
    height: inherit;
    padding: 10px;
    .left-top {
      padding: 1rem;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: rgba(13, 28, 83, 0.3);
      .title-dis {
        margin-top: 0.9375;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 1rem;
        color: #c0c9d2;
        &-keyword {
          padding-left: 0.625rem;
          color: #47dae8;
        }
      }
    }
  }
}

.centerBottom {
  padding: 0.2rem 0.7rem;
  .detail-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
    justify-content: space-around;
    width: 100%;
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 3.4rem;
      width: 98%;
      padding: 0 0.2rem;
      margin-bottom: 0.4rem;
      margin-top: 0.2rem;
      border-radius: 5px;
      border: 1px solid #343f4b;
      background-color: rgba(19, 25, 47, 0.8);
      .detail-item-text {
        display: flex;
        width: 98%;
        justify-content: space-around;
        h3 {
          color: #bcdcff;
          font-size: 0.1rem;
          margin-bottom: 0.3rem;
        }
      }
    }
  }
}

.el-table,
.el-table__expanded-cell {
  background-color: transparent;
}
::v-deep .el-table tr {
  background-color: transparent;
}
::v-deep .el-table th.el-table__cell {
  background-color: transparent;
  border: 1px solid #4d67b7;
}
::v-deep .el-table td.el-table__cell {
  border: 1px solid #4d67b7;
}
::v-deep .el-table--border,
.el-table--group {
  border: 1px solid #4d67b7;
}
::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: "";
  position: absolute;
  background-color: #4d67b7;
  z-index: 1;
}
::v-deep .el-table thead {
  color: #bcdcff;
  font-weight: 500;
}
::v-deep .el-table {
  color: #bcdcff;
}
::v-deep.el-table tbody tr:hover > td {
  background-color: transparent !important;
}
::v-deep .el-table__body tr.current-row > td.el-table__cell {
  background-color: transparent;
}

.tag-style {
  padding: 0.1rem 0.2rem;
  margin: 0.1rem;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
}
.el-table .cell {
  white-space: nowrap;
  line-height: 23px;
  padding-right: 5px;
}
</style>
