<template>
  <div>
    <div :id="chartId" style="width:7.25rem;height:7.25rem"></div>
  </div>
</template>

<script>
import * as echarts from "echarts"
var leiDom
var leiChart
var leioption
export default {
  props: {
    chartData: {
      type: Object,
      require: true,
    },
    chartId: {
      type: String,
      require: true,
    },
  },
  // watch:{
  //   chartId(chartId){
  //     console.log(chartId);
  //     // this.leidaChatCanvas(id)
  //   }
  // },
  mounted() {
    this.$nextTick(() => {
      this.feedChatCanvas()
    })
    //  console.log(this.chartId);
  },
  methods: {
    feedChatCanvas() {
      // console.log(id);
      leiDom = document.getElementById(this.chartId)
      // if (leiChart != null && leiChart != "" && leiChart != undefined) {
      //   leiChart.dispose(); //销毁
      // }
      leiChart = echarts.init(leiDom)
      leioption = {
        title: {
          text: this.chartData.number+"%",
          left: "45%",
          top: "40%",
          textAlign: "center",
          textStyle: {
            fontSize: "16",
            fontWeight: "500",
            color: "#909dff",
            textAlign: "center",
          },
        },
        series: [
          {
            type: "pie",
            startAngle: 0,
            radius: ["80%", "70%"],
            center: ["50%", "50%"],
            data: [
              {
                value: this.chartData.number,
                name: this.chartData.title,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#5a8bfa",
                      },
                      {
                        offset: 1,
                        color: "#831bdb",
                      },
                    ]),
                    shadowColor: "rgba(175,143,230,.5)",
                    shadowBlur: 10,
                  },
                },
                label: {
                  show: false,
                },
                labelLine: {
                  normal: {
                    smooth: true,
                    lineStyle: {
                      width: 0,
                    },
                  },
                },
                hoverAnimation: false,
              },
              {
                label: {
                  show: false,
                },
                labelLine: {
                  normal: {
                    smooth: true,
                    lineStyle: {
                      width: 0,
                    },
                  },
                },
                value: 100 - this.chartData.number,
                hoverAnimation: true,
                itemStyle: {
                  color: "rgba(79,76,192, 0.3)",
                },
              },
            ],
          },
        ],
      }
      leiChart.setOption(leioption)
      // window.addEventListener("resize", function() {
      //   leiChart.resize(); //下面可以接多个图
      // });
    },
  },
}
</script>

<style></style>
