<template>
  <div>
    <div class="left-top" style="display: flex;flex-direction: column;">
      <div class="module-title">
        <i class="iconfont">&#xe78f;</i>
        <span>培训资源</span>
      </div>
      <div>
        <div
          class="item-box mt-2"
          style="margin-top:0.375rem"
          v-for="(item, index) in numberData"
          :key="index"
        >
          <div class="d-flex" style="display:flex;just-contentfy:center">
            <dv-digital-flop class="dv-digital-flop" :config="item.number" />
          </div>
          <p class="text" style="text-align: center;">
            {{ item.text }}
            <span class="colorYellow">({{ item.unit }})</span>
          </p>
        </div>
      </div>
      <div class="module-title">
        <i class="iconfont">&#xe78f;</i>
        <span>残疾人工作企业分布</span>
        <dv-capsuleChart :config="config" class="capsule"></dv-capsuleChart>
      </div>
    </div>
  </div>
</template>

<script>
const formatter = (number) => {
  const numbers = number
    .toString()
    .split("")
    .reverse()
  const segs = []

  while (numbers.length) segs.push(numbers.splice(0, 3).join(""))

  return segs
    .join(",")
    .split("")
    .reverse()
    .join("")
}
export default {
  data() {
    return {
       config: {
        // 单位
        unit: "（个/月）",
        showValue: false,
        data: [
          {
            name: "职业技能指导培训",
            value: 50,
          },
          {
            name: "温馨家园运营培训",
            value: 20,
          },
          {
            name: "残建融合培训",
            value: 10,
          },
          
          {
            name: "康复师培训",
            value: 8,
          },
          {
            name: "护理师培训",
            value: 6,
          },
          {
            name: "社工师培训",
            value: 7,
          },
        ],
      },
      numberData: [
        {
          number: {
            number: [38],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}",
            style: {
              fontSize: 16,
            },
            formatter,
          },
          text: "非遗大师",
          unit: "名",
        },
        {
          number: {
            number: [191],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}",
            style: {
              fontSize: 16,
            },
            formatter,
          },
          text: "指导老师",
          unit: "名",
        },
        {
          number: {
            number: [231],
            toFixed: 0,
            textAlign: "center",
            content: "{nt}",
            style: {
              fontSize: 16,
            },
            formatter,
          },
          text: "培训机构/师资",
          unit: "个",
        },
      ],
      detailsList: [
        {
          title: "赋能机构数量",
          number: "310",
          unit: "个",
        },
        {
          title: "服务城市数量",
          number: "60",
          unit: "个",
        },
        {
          title: "链接爱心企业",
          number: 3000,
          unit: "家",
        },
        {
          title: "培训课程数量",
          number: 87,
          unit: "门",
        },
        {
          title: "就业岗位类型",
          number: 28,
          unit: "类",
        },
        {
          title: "文娱活动数量",
          number: "1,023",
          unit: "次",
        },
      ],
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.left {
  width: 28.5rem;
  height: auto;
  // padding: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0px;
}

.leftTop {
  position: relative;
  height: 16rem;
  width: 100%;
}
.leftButtom {
  position: relative;
  margin-top: 1.25rem;
  height: 38.75rem;
  width: 100%;
  .left-bottom-borderBox13 {
    width: inherit;
    height: inherit;
    padding: 1.25rem 0.9375rem;
    .left-bottom {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: rgba(13, 28, 83, 0.3);
    }
  }
}
.module-title {
  padding: 18px 10px 0 10px;
  color: #bcdcff;
  font-size: 1rem;
  font-weight: bold;
  .iconfont {
    font-size: 0.875rem;
    margin-right: 0.625rem;
    color: #89e5ff;
    font-weight: 400;
  }
}
.left_dang_source {
  width: 27.675rem;
  height: 14.5rem;
}
.left_today {
  width: 27.675rem;
  height: 12rem;
}
.left_dang {
  width: 27.675rem;
  height: 11rem;
}
.user-data {
  width: 28.375rem;
  height: 34.375rem;
}

.item-box {
  & > div {
    padding-right: 5px;
  }
  font-size: 10px;
  float: right;
  position: relative;
  width: 32%;
  color: #d3d6dd;
  .dv-digital-flop {
    width: 100%;
    height: 30px;
  }
  // 金币
  .coin {
    position: relative;
    top: 6px;
    font-size: 20px;
    color: #ffc107;
  }
  .colorYellow {
    color: yellowgreen;
  }
  p {
    text-align: center;
  }
}

.centerBottom {
  padding: 0.2rem 0.7rem;
  .detail-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
    justify-content: space-around;
    width: 100%;
    &-item {
      display: flex;
      align-items: center;
      position: relative;
      height: 5.25rem;
      width: 32%;
      padding: 0 0.2rem;
      margin-bottom: 0.2rem;
      border-radius: 5px;
      border: 1px solid #343f4b;
      background-color: rgba(19, 25, 47, 0.8);
      img {
        width: 3.25rem;
        height: 3.25rem;
      }
      .detail-item-text {
        margin-left: 0.225rem;
        h3 {
          color: #bcdcff;
          font-size: 0.25rem;
          margin-bottom: 0.3rem;
        }
        span {
          font-weight: 500px;
          font-size: 0.25rem;
          font-weight: bolder;
          background: linear-gradient(to bottom, #fff, #4db6e5);
          color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
        }
        .unit {
          font-size: 0.25rem;
          margin-left: 0.625rem;
        }
      }
    }
  }
}

.capsule {
  height: 14rem;
}
</style>
