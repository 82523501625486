<template>
  <div class="centerBot">
    <dv-borderBox12 class="left-top-borderBox12">
      <div class="left-top">
        <div style="display: flex;justify-content: space-around;">
          <div
            class="feedback-box-item"
            v-for="(item, index) in saasData"
            :key="index"
          >
            <span class="dis-text">{{ item.title }}</span>
            <Feed :chart-id="'feed' + index" :chart-data="item"></Feed>
          </div>
        </div>

        <div
          style="display: flex;justify-content: space-around;margin-top:1rem;"
        >
          <div id="linepo" style="height:14rem;width:16rem"></div>
          <div id="qunti" style="height:14rem;width:16rem"></div>
          <div id="manyi" style="height:14rem;width:16rem"></div>
        </div>

        <div
          style="display: flex;justify-content: space-around;margin-top:1rem;"
        >
          <div id="active" style="height:15rem;width:17rem"></div>
          <div id="renshu" style="height:15rem;width:17rem"></div>
          <div id="biaoqian" style="height:15rem;width:17rem"></div>
        </div>
        <div style="display: flex;justify-content: space-around;">
          <div id="timeLine" style="height:5rem;width:48rem"></div>
        </div>
      </div>
    </dv-borderBox12>
  </div>
</template>

<script>
import * as echarts from "echarts"
import Feed from "./feed.vue"
import "echarts-wordcloud"

export default {
  components: {
    Feed,
  },

  data() {
    return {
      saasData: [
        {
          title: "服务覆盖率",
          number: 95,
        },
        {
          title: "线上/线下比例",
          number: 70,
        },
        {
          title: "总体满意度",
          number: 98,
        },
      ],
      peoledata: {
        xdata: ["1月", "2月", "3月", "4月", "5月"],
        ydata1: [150, 52, 136, 105, 118],
      },
    }
  },
  beforeDestroy() {
    // clearInterval(this.intervalId)
  },
  mounted() {
    // this.startInterval()
    this.getlineOptions()
    this.getquntiOptions()
    this.getManyiOptions()

    this.getActiveTypeOptions()
    this.getRenshuOptions()
    this.getBiaoqianOptins()

    this.getTimeLine()
  },
  methods: {
    // startInterval() {
    //   const _self = this
    //   // 应通过接口获取配置时间，暂时写死5s
    //   const time = 10000
    //   if (this.intervalId !== null) {
    //     clearInterval(this.intervalId)
    //   }
    //   this.intervalId = setInterval(() => {
    //     _self.reSelectRandom()
    //   }, time)
    // },
    getlineOptions() {
      if (document.getElementById("linepo") == null) {
        return
      }
      var linepochartDom
      var linepoChart
      if (linepochartDom == null && linepoChart == null) {
        linepochartDom = document.getElementById("linepo")
        linepoChart = echarts.init(linepochartDom)
      }

      var charts = {
        unit: "",
        names: ["活动人数", "活动次数"],
        lineX: this.peoledata.xdata,
        value: [this.peoledata.ydata1],
      }
      var color = ["rgba(93, 255, 255"]
      var lineY = []

      for (var i = 0; i < charts.names.length; i++) {
        var x = i
        if (x > color.length - 1) {
          x = color.length - 1
        }
        var data = {
          name: charts.names[i],
          type: "line",
          color: color[x] + ")",
          // yAxisIndex: i,
          smooth: false,
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: color[x] + ", 0.3)",
                  },
                  {
                    offset: 0.8,
                    color: color[x] + ", 0)",
                  },
                ],
                false
              ),
              shadowColor: "rgba(0, 0, 0, 0.1)",
              shadowBlur: 10,
            },
          },
          symbol: "circle",
          symbolSize: 5,
          data: charts.value[i],
        }
        lineY.push(data)
      }

      var option = {
        title: {
          text: "活动组织趋势",
          left: "center",
          top: -5,
          textStyle: {
            fontSize: 14,
            color: "#ffffff",
            fontWeight: 400,
          },
        },
        tooltip: {
          trigger: "axis",
        },

        legend: {
          show: false,
          top: "2%",
          data: charts.names,
          textStyle: {
            fontSize: 14,
            color: "F1F1F3",
          },
          right: "4%",
        },
        grid: {
          top: "20%",
          left: "4%",
          right: "4%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          show: true,
          type: "category",
          boundaryGap: false,
          data: charts.lineX,
          axisLabel: {
            textStyle: {
              color: "rgb(0,253,255,0.6)",
            },
            // formatter: function(params) {
            //   return params.split(" ")[0] + "\n" + params.split(" ")[1]
            // },
          },
        },
        yAxis: {
          show: true,
          splitArea: {
            show: true,
            areaStyle: {
              color: "rgba(1, 22, 53, 1)",
            },
          },
          type: "value",
          axisLabel: {
            formatter: "{value}",
            textStyle: {
              color: "rgb(0,253,255,0.6)",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgb(23,255,243,0.3)",
            },
          },

          axisLine: {
            show: true,
            lineStyle: {
              color: "rgb(0,253,255,0.6)",
            },
          },
        },
        series: lineY,
      }
      linepoChart.setOption(option)
      window.addEventListener("resize", function() {
        linepoChart.resize() //下面可以接多个图
      })
    },

    getquntiOptions() {
      var chartDom = document.getElementById("qunti")
      var myChart = echarts.init(chartDom)
      var option
      option = {
        title: {
          text: "残疾群体参与度",
          left: "center",
          top: -5,
          textStyle: {
            fontSize: 14,
            color: "#ffffff",
            fontWeight: 400,
          },
        },
        legend: {
          show: false,
          data: ["采样人数", "检测人数"],
          top: "5%",
          textStyle: {
            color: "rgba(250,250,250,0.6)",
            fontSize: 12,
          },
        },
        barWidth: 7,
        grid: {
          top: "20%",
          left: "4%",
          right: "4%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.2)",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            //  改变x轴颜色
            lineStyle: {
              color: "transparent",
            },
          },
          axisLabel: {
            //  改变x轴字体颜色和大小
            textStyle: {
              color: "rgba(250,250,250,0.6)",
              fontSize: 12,
            },
          },
        },
        yAxis: {
          type: "category",
          data: ["1月", "2月", "3月", "4月", "5月"],
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            //  改变y轴颜色
            lineStyle: {
              color: "#26D9FF",
            },
          },
          axisLabel: {
            //  改变y轴字体颜色和大小
            //formatter: '{value} m³ ', //  给y轴添加单位
            textStyle: {
              color: "rgba(250,250,250,0.6)",
              fontSize: 12,
            },
          },
        },
        series: [
          {
            type: "bar",
            itemStyle: {
              normal: {
                label: {
                  show: false, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "rgba(250,250,250,0.6)",
                    fontSize: 16,
                    fontWeight: 600,
                  },
                },
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: "rgba(61,126,235,1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(61,126,235,0)",
                  },
                ]),
                borderWidth: 2,
                barBorderRadius: 15,
              },
            },
            data: [12, 15, 20, 22, 25],
          },
          // {
          //   type: "bar",
          //   name: "检测人数",
          //   itemStyle: {
          //     normal: {
          //       label: {
          //         show: false, //开启显示
          //         position: "right", //在上方显示
          //         textStyle: {
          //           //数值样式
          //           color: "rgba(250,250,250,0.6)",
          //           fontSize: 12,
          //           fontWeight: 600,
          //         },
          //       },
          //       color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
          //         {
          //           offset: 0,
          //           color: "rgba(15,197,243,1)",
          //         },
          //         {
          //           offset: 1,
          //           color: "rgba(15,197,243,0)",
          //         },
          //       ]),
          //       borderWidth: 2,
          //       barBorderRadius: 15,
          //     },
          //   },
          //   data: [12, 23, 35, 100, 45],
          // },
        ],
      }
      myChart.setOption(option)
      window.addEventListener("resize", function() {
        myChart.resize() //下面可以接多个图
      })
    },

    getManyiOptions() {
      var chartManyi = document.getElementById("manyi")
      var ManyiChart = echarts.init(chartManyi)
      var option
      let dateArr = ["1月", "2月", "3月", "4月", "5月"]
      let sportCount = [96, 95, 98, 96, 97]
      // let sportTime = [3, 2, 3, 5, 4]
      option = {
        title: {
          text: "残疾群体满意度",
          left: "center",
          top: -5,
          textStyle: {
            fontSize: 14,
            color: "#ffffff",
            fontWeight: 400,
          },
        },
        grid: {
          top: "20%",
          left: "4%",
          right: "4%",
          bottom: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
        },

        xAxis: {
          data: dateArr,
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: "rgb(0,253,255,0.6)",
            },
          },
          axisTick: {
            show: false, //隐藏X轴刻度
          },
          axisLabel: {
            textStyle: {
              formatter: "{value}",
              textStyle: {
                color: "rgb(0,253,255,0.6)",
              },
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#284269",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgb(0,253,255,0.6)",
            },
          },
          axisLabel: {
            formatter: "{value}",
            textStyle: {
              color: "rgb(0,253,255,0.6)",
            },
          },
        },

        series: [
          {
            type: "bar",
            barWidth: 12,
            itemStyle: {
              normal: {
                color: "#39ADFF",
              },
            },
            data: sportCount,
          },
          // {
          //   name: "人均用时",
          //   type: "bar",
          //   barWidth: 12,
          //   itemStyle: {
          //     normal: {
          //       color: "#47E0E0",
          //     },
          //   },
          //   data: sportTime,
          // },
        ],
      }
      ManyiChart.setOption(option)
      window.addEventListener("resize", function() {
        ManyiChart.resize() //下面可以接多个图
      })
    },

    //------------
    getActiveTypeOptions() {
      var chartactive = document.getElementById("active")
      var activeChart = echarts.init(chartactive)
      var option

      var datas = [
        {
          name: "党建活动",
          value: 10,
        },

        {
          name: "康复训练",
          value: 15,
        },
        {
          name: "生活训练",
          value: 15,
        },
        {
          name: "文体活动",
          value: 10,
        },
        {
          name: "企业慰问活动",
          value: 10,
        },
        {
          name: "职康劳动",
          value: 25,
        },
        {
          name: "技能培训",
          value: 15,
        },
      ]
      var max = Math.max(...datas.map((item) => item.value))

      var data = []
      var color = [
        "#2ca1ff",
        "#0adbfa",
        "#febe13",
        "#65e5dd",
        "#7b2cff",
        "#fd5151",
        "#f071ff",
        "#85f67a",
      ]
      for (var i = 0; i < datas.length; i++) {
        data.push(
          {
            value: datas[i].value,
            name: datas[i].name,
            itemStyle: {
              normal: {
                borderWidth: 0,
                shadowBlur: 10,
                shadowColor: color[i],
              },
            },
          },
          {
            value: max * 0.05,
            name: "",
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                color: "rgba(0, 0, 0, 0)",
                borderColor: "rgba(0, 0, 0, 0)",
                borderWidth: 0,
              },
            },
            label: {
              show: false,
            },
            tooltip: {
              show: false,
            },
          }
        )
      }

      option = {
        color: color,
        title: {
          text: "活动类型占比",
          left: "center",
          top: -5,
          textStyle: {
            fontSize: 14,
            color: "#ffffff",
            fontWeight: 400,
          },
        },
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
        },
        series: [
          {
            name: "",
            type: "pie",
            clockWise: false,
            radius: ["28%", "48%"],
            hoverAnimation: false,
            center: ["50%", "50%"],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "outside",
                  color: "#ddd",
                  fontSize: 12,
                  formatter: function(params) {
                    if (params.name !== "") {
                      return params.name + "\n" + "\n" + params.value + "%"
                    } else {
                      return ""
                    }
                  },
                },
              },
            },
            label: {
              show: true,
              formatter: "{c}%",
              textStyle: {
                fontSize: 12,
                color: "#fff",
              },
            },
            data: data,
          },
        ],
      }
      activeChart.setOption(option)
      window.addEventListener("resize", function() {
        activeChart.resize() //下面可以接多个图
      })
    },

    getRenshuOptions() {
      var chartrenshu = document.getElementById("renshu")
      var renshuChart = echarts.init(chartrenshu)
      var option

      var datas = [
        {
          name: "20-30人",
          value: 55,
        },
        {
          name: "30-40人",
          value: 35,
        },
        {
          name: "40人以上",
          value: 10,
        },
      ]
      var max = Math.max(...datas.map((item) => item.value))

      var data = []
      var color = [
        "#2ca1ff",
        "#0adbfa",
        "#febe13",
        "#65e5dd",
        "#7b2cff",
        "#fd5151",
        "#f071ff",
        "#85f67a",
      ]
      for (var i = 0; i < datas.length; i++) {
        data.push(
          {
            value: datas[i].value,
            name: datas[i].name,
            itemStyle: {
              normal: {
                borderWidth: 0,
                shadowBlur: 10,
                shadowColor: color[i],
              },
            },
          },
          {
            value: max * 0.05,
            name: "",
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                color: "rgba(0, 0, 0, 0)",
                borderColor: "rgba(0, 0, 0, 0)",
                borderWidth: 0,
              },
            },
            label: {
              show: false,
            },
            tooltip: {
              show: false,
            },
          }
        )
      }

      option = {
        color: color,
        title: {
          text: "活动人数占比",
          left: "center",
          top: -5,
          textStyle: {
            fontSize: 14,
            color: "#ffffff",
            fontWeight: 400,
          },
        },
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
        },
        series: [
          {
            name: "",
            type: "pie",
            clockWise: false,
            radius: ["30%", "50%"],
            hoverAnimation: false,
            center: ["50%", "50%"],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "outside",
                  color: "#ddd",
                  formatter: function(params) {
                    if (params.name !== "") {
                      return params.name + "\n" + "\n" + params.value + "%"
                    } else {
                      return ""
                    }
                  },
                },
              },
            },
            label: {
              show: true,
              formatter: "{c}%",
              textStyle: {
                fontSize: 16,
                color: "#fff",
              },
            },
            data: data,
          },
        ],
      }
      renshuChart.setOption(option)
      window.addEventListener("resize", function() {
        renshuChart.resize() //下面可以接多个图
      })
    },

    getBiaoqianOptins() {
      var biaoqian = document.getElementById("biaoqian")
      var biaoqianChart = echarts.init(biaoqian)
      let datas = [
        {
          name: "满意",
          value: 30,
        },
        {
          name: "有意义",
          value: 28,
        },
        {
          name: "充实",
          value: 24,
        },
        {
          name: "活动丰富",
          value: 23,
        },
        {
          name: "学有所获",
          value: 22,
        },
        {
          name: "多组织户外活动",
          value: 25,
        },
        {
          name: "开心",
          value: 24,
        },
        {
          name: "安全",
          value: 29,
        },
        {
          name: "学到知识",
          value: 28,
        },
        {
          name: "放心",
          value: 27,
        },
        {
          name: "融入社会",
          value: 26,
        },
        {
          name: "自力更生",
          value: 25,
        },
      ]

      var option
      option = {
        tooltip: {
          show: true,
          position: "top",
          textStyle: {
            fontSize: 30,
          },
        },
        series: [
          {
            type: "wordCloud",
            // 网格大小，各项之间间距
            gridSize: 10,
            // 形状 circle 圆，cardioid  心， diamond 菱形，
            // triangle-forward 、triangle 三角，star五角星
            shape: "circle",
            // 字体大小范围
            sizeRange: [10, 30],
            // 文字旋转角度范围
            rotationRange: [0, 0],
            // 旋转步值
            // rotationStep: 90,
            // 自定义图形
            // maskImage: maskImage,
            left: "center",
            top: "center",
            right: null,
            bottom: null,
            // 画布宽
            width: "90%",
            // 画布高
            height: "80%",
            // 是否渲染超出画布的文字
            drawOutOfBound: false,
            textStyle: {
              normal: {
                color: function() {
                  return (
                    "rgb(" +
                    [
                      Math.round(Math.random() * 200 + 55),
                      Math.round(Math.random() * 200 + 55),
                      Math.round(Math.random() * 200 + 55),
                    ].join(",") +
                    ")"
                  )
                },
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: "#2ac",
              },
            },
            data: datas,
          },
        ],
      }
      biaoqianChart.setOption(option)
      window.addEventListener("resize", function() {
        biaoqianChart.resize() //下面可以接多个图
      })
    },

    //----
    getTimeLine() {
      var timeLine = document.getElementById("timeLine")
      var timeLineChart = echarts.init(timeLine)

      var time = ["活动前", "活动中", "活动后"],
        option = {
          timeline: {
            top: 1,
            left: 100,
            right: 100,
            tooltip: {
              show: false,
            },
            // playInterval: "5000",
            axisType: "category",
            // autoPlay: true,
            symbol: "circle",
            symbolSize: 14,
            itemStyle: {
              normal: {
                color: "#3cd1fc",
                borderColor: "#1f79ff",
                borderWidth: 2,
              },
              emphasis: {
                color: "#3cd1fc",
              },
            },
            label: {
              normal: {
                textStyle: {
                  fontSize: 14,
                  color: "#1f79ff",
                },
              },
              emphasis: {
                textStyle: {
                  fontSize: 14,
                  color: "#3dd4ff",
                },
              },
            },
            lineStyle: {
              color: "#1f79ff",
              width: 2,
            },
            checkpointStyle: {
              color: "#3dd4ff",
              borderColor: "#1f79ff",
              borderWidth: 2,
            },
            controlStyle: {
              show: false,
            },
            data: time,
          },
        }
      timeLineChart.setOption(option)
      window.addEventListener("resize", function() {
        timeLineChart.resize() //下面可以接多个图
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.centerBot {
  position: relative;
  height: 47.5rem;
  width: 57.625rem;
  display: flex;
  margin-top: 3rem;
  .left-top-borderBox12 {
    width: inherit;
    height: inherit;
    padding: 10px;
    .left-top {
      padding: 1rem;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: rgba(13, 28, 83, 0.3);
    }
  }
}

.feedback-box-item {
  text-align: center;
}
</style>
