<template>
  <div class="centerBot">
    <dv-borderBox12 class="left-top-borderBox12">
      <div class="left-top">
        <div class="module-title">
          <img class="iconfont" src="@/assets/sell.png" alt="" />
          <span>{{ saleMounth }}回款及销售目标</span>
        </div>
        <div style="padding:10px">
          <el-date-picker
            v-model="month"
            format="yyyy-MM"
            value-format="yyyy-MM"
            type="month"
            placeholder="选择月份"
            @change="handlerDate"
          >
          </el-date-picker>
        </div>

        <div style="display: flex;justify-content: space-around;height:15.5rem">
          <el-table :data="salesDataList" border style="width: 100%">
            <el-table-column align="center" label="中心">
              <template slot-scope="{ row }">
                <span>{{ row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              min-width="60px"
              label="回款金额(万)"
            >
              <template slot-scope="{ row }">
                <span>{{ row.back_money }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              min-width="60px"
              label="签单金额(万)"
            >
              <template slot-scope="{ row }">
                <span>{{ row.monad_money }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              min-width="50px"
              label="高意向客户数"
            >
              <template slot-scope="{ row }">
                <span>{{ row.intent_high_num }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              min-width="50px"
              label="中意向客户数"
            >
              <template slot-scope="{ row }">
                <span>{{ row.intent_mid_num }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              min-width="50px"
              label="低意向客户数"
            >
              <template slot-scope="{ row }">
                <span>{{ row.intent_low_num }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top:20px;color:#fff">
          注：以上数据显示为 目标 / 完成
        </div>
      </div>
    </dv-borderBox12>
  </div>
</template>

<script>
// import * as echarts from "echarts"
// import { getSalesList } from "../../utils/log"
// import axios from "axios"
import { saleTarget } from "../../api/sale"

export default {
  data() {
    return {
      month: "",
      nameDataList: [
        {
          person: {
            name: "彭*易",
            sex: "女",
            age: 19,
            level: "中专",
            disable_type: "智力残疾",
            disable_level: "二级",
          },
          person_label: [
            "女",
            "中专",
            "二级",
            "阅读",
            "绘画",
            "16-25岁",
            "智力残疾",
            "月坛街道",
            "唱歌",
          ],
          rules: [
            {
              name: "《基本康复目录(2019年)》",
              demand: ["本市户籍", "年龄在16周岁及以上", "智力残疾人"],
            },
            {
              name: "《就业保障金征收使用管理办法》",
              demand: [
                "就业年龄段内",
                "签订1年以上（含1年）劳动合同",
                "实际支付的工资不低于北京市最低工资标准",
                "足额缴纳社会保险费",
              ],
            },
            {
              name: "《职业康复劳动项目基本条件》",
              demand: ["本市户籍", "就业年龄段内"],
            },
          ],
          resource: [
            {
              name: "康复资源",
              demand: ["中康", "英智"],
            },
            {
              name: "培训资源",
              demand: ["智选职康站", "西城区职康站"],
            },
            {
              name: "就业资源",
              demand: ["FESCO", "招聘企业"],
            },
          ],
          service: [
            {
              name: "康复",
              zhengce: "《基本康复目录(2019年)》",
              source1: "中康",
              source2: "北康",
              active: "社会生活能力的行为训练",
            },
            {
              name: "劳动就业",
              zhengce: "《就业保障金征收使用管理办法》",
              source1: "FESCO",
              source2: "爱心企业",
              active: "就业岗位推荐",
            },
            {
              name: "职业康复",
              zhengce: "《职业康复劳动项目基本条件》",
              source1: "智选职康站",
              source2: "西城区职康站",
              active: "职业康复劳动项目",
            },
          ],
        },
        {
          person: {
            name: "郭*燕",
            sex: "女",
            age: 39,
            level: "大专",
            disable_type: "精神残疾",
            disable_level: "二级",
          },
          person_label: [
            "女",
            "大专",
            "二级",
            "摄影",
            "手工制作",
            "36-40岁",
            "精神残疾",
            "牛街街道",
            "唱歌",
          ],
          rules: [
            {
              name: "《基本康复目录(2019年)》",
              demand: ["本市户籍", "年龄在16周岁及以上", "精神残疾人"],
            },
            {
              name: "《职业技能提升培训实施细则》",
              demand: ["持证残疾人", "本市户籍", "就业年龄段内"],
            },
            {
              name: "《残疾人持卡免费进入景区的通告》",
              demand: ["持证残疾人", "市属公园"],
            },
          ],
          resource: [
            {
              name: "康复资源",
              demand: ["中康", "英智"],
            },
            {
              name: "培训资源",
              demand: ["智选培训学校", "非遗工作室"],
            },
            {
              name: "就业资源",
              demand: ["智选职康站", "西城区职康站"],
            },
          ],
          service: [
            {
              name: "康复",
              zhengce: "《基本康复目录（2019年）》",
              source1: "中康",
              source2: "北康",
              active: "回归社会技能训练",
            },
            {
              name: "劳动就业",
              zhengce: "《职业技能提升培训实施细则》",
              source1: "智选培训学校",
              source2: "非遗工作室",
              active: "景泰蓝制作培训",
            },
            {
              name: "文化休闲",
              zhengce: "《残疾人持卡免费进入景区的通告》",
              source1: "玉渊潭公园",
              source2: "香山公园",
              active: "“迎冬奥，健步走”活动",
            },
          ],
        },
        {
          person: {
            name: "赵*",
            sex: "男",
            age: 40,
            level: "本科",
            disable_type: "肢体残疾",
            disable_level: "一级",
          },
          person_label: [
            "男",
            "肢体残疾",
            "本科",
            "计算机",
            "36-40岁",
            "一级",
            "新华街道",
            "唱歌",
          ],
          rules: [
            {
              name: "《居家环境无障碍改造服务管理办法》",
              demand: ["持证残疾人", "本市户籍", "未实施过居家改造的残疾人"],
            },
            {
              name: "《职业技能提升培训实施细则》",
              demand: ["持证残疾人", "本市户籍", "就业年龄段内"],
            },
            {
              name: "《就业保障金征收使用管理办法》",
              demand: [
                "就业年龄段内",
                "签订1年以上（含1年）劳动合同",
                "实际支付的工资不低于北京市最低工资标准",
                "足额缴纳社会保险费",
              ],
            },
          ],
          resource: [
            {
              name: "无障碍改造",
              demand: ["残联", "居家无障碍改造机构"],
            },
            {
              name: "培训资源",
              demand: ["智选职业技能培训学校", "技能培训学校"],
            },
            {
              name: "就业资源",
              demand: ["国华众联", "互联网企业"],
            },
          ],
          service: [
            {
              name: "无障碍",
              zhengce: "《居家环境无障碍改造服务管理办法》",
              source1: "中康",
              source2: "北康",
              active: "回归社会技能训练",
            },
            {
              name: "劳动就业",
              zhengce: "《职业技能提升培训实施细则》",
              source1: "智选培训学校",
              source2: "非遗工作室",
              active: "景泰蓝制作培训",
            },
            {
              name: "劳动就业",
              zhengce: "《就业保障金征收使用管理办法》",
              source1: "国华众联",
              source2: "互联网企业",
              active: "就业岗位推荐",
            },
          ],
        },
      ],
      dataList: [
        {
          center: "产品研发中心",
          backMoney: "35.00 / 0",
          sourceMoney: "63.00 / 0",
          hight: "3 / 0",
          middle: "1 / 0",
          low: "3 / 0",
        },
        {
          center: "市场营销中心",
          backMoney: "40.50 / 0",
          sourceMoney: "50.90 / 0",
          hight: "22 / 0",
          middle: "40 / 0",
          low: "117 / 0",
        },
        {
          center: "客服运营中心",
          backMoney: "359.60 / 0",
          sourceMoney: "94.03 / 0",
          hight: "10 / 0",
          middle: "10 / 0",
          low: "71 / 0",
        },
        {
          center: "合计",
          backMoney: "435.10 / 0",
          sourceMoney: "207.93 / 0",
          hight: "35 / 0",
          middle: "51 / 0",
          low: "188 / 0",
        },
      ],
      incomeList: [
        { payment_date: "222" },
        { payment_date: "222" },
        { payment_date: "222" },
      ],
      nameData: {},
      salesDataList: [],
      saleMounth: "",
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  mounted() {
    // this.nameData = this.nameDataList[0]
    // this.startInterval()
    this.month =
      this.dateFormat(new Date().getFullYear()) +
      "-" +
      this.dateFormat(new Date().getMonth() + 1)
    this.getSales()
  },
  methods: {
    dateFormat(date) {
      return date < 10 ? `0${date}` : date
    },

    handlerDate() {
      this.getSales()
    },
    async getSales() {
      let params = {
        month: this.month.split("-")[1],
      }
      const { data } = await saleTarget(params)
      this.salesDataList = data.list
      this.saleMounth = data.title
      console.log(data)
      // var that = this
      // http://10.20.28.190:5200
      // http://operate.guohualinks.com/cockpitapi/wechat/CenterScreenData
      // axios
      //   .get(
      //     "http://operate.guohualinks.com/cockpitapi/wechat/CenterScreenData?month=" +
      //       this.month.split("-")[1]
      //   )
      //   .then(function(response) {
      //     const { data } = response
      //     that.salesDataList = data.data.list
      //     that.saleMounth = data.data.title
      //   })
      //   .catch(function(err) {
      //     console.log(err)
      //   })
    },
    RoundColor() {
      return (
        "rgb(" +
        [
          Math.round(Math.random() * 200 + 55),
          Math.round(Math.random() * 200 + 55),
          Math.round(Math.random() * 200 + 55),
        ].join(",") +
        ")"
      )
    },
    startInterval() {
      const _self = this
      // 应通过接口获取配置时间，暂时写死5s
      const time = 3000
      if (this.intervalId !== null) {
        clearInterval(this.intervalId)
      }
      this.intervalId = setInterval(() => {
        _self.reSelectRandom()
      }, time)
    },
    reSelectRandom() {
      var rand = Math.round(Math.random() * 2)
      this.$nextTick(() => {
        this.nameData = this.nameDataList[rand]
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.module-title {
  padding: 30px 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: bold;
  .iconfont {
    margin-right: 0.625rem;
    width: 4rem;
    font-weight: 400;
  }
  // span{
  // }
}
.module-content {
  padding: 12px 12px;
  color: #bcdcff;
  font-size: 1rem;
  .iconfont {
    font-size: 0.875rem;
    margin-right: 0.625rem;
    color: #89e5ff;
    font-weight: 400;
  }
}
.centerBot {
  position: relative;
  height: 47.5rem;
  width: 57.625rem;
  display: flex;
  margin-top: 3rem;
  // margin-right: 2rem;
  .left-top-borderBox12 {
    width: inherit;
    height: inherit;
    padding: 10px;
    .left-top {
      padding: 1rem;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: rgba(13, 28, 83, 0.3);
      .title-dis {
        margin-top: 0.9375;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 1rem;
        color: #c0c9d2;
        &-keyword {
          padding-left: 0.625rem;
          color: #47dae8;
        }
      }
    }
  }
}

.centerBottom {
  padding: 0.2rem 0.7rem;
  .detail-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
    justify-content: space-around;
    width: 100%;
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 3.4rem;
      width: 98%;
      padding: 0 0.2rem;
      margin-bottom: 0.4rem;
      margin-top: 0.2rem;
      border-radius: 5px;
      border: 1px solid #343f4b;
      background-color: rgba(19, 25, 47, 0.8);
      .detail-item-text {
        display: flex;
        width: 98%;
        justify-content: space-around;
        h3 {
          color: #bcdcff;
          font-size: 0.1rem;
          margin-bottom: 0.3rem;
        }
      }
    }
  }
}

.el-table,
.el-table__expanded-cell {
  background-color: transparent;
}
::v-deep .el-table tr {
  background-color: transparent;
}
::v-deep .el-table th.el-table__cell {
  background-color: transparent;
  border: 1px solid #4d67b7;
}
::v-deep .el-table td.el-table__cell {
  border: 1px solid #4d67b7;
}
::v-deep .el-table--border,
.el-table--group {
  border: 1px solid #4d67b7;
}
::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: "";
  position: absolute;
  background-color: #4d67b7;
  z-index: 1;
}
::v-deep .el-table thead {
  color: #bcdcff;
  font-weight: 500;
}
::v-deep .el-table {
  color: #bcdcff;
}
::v-deep.el-table tbody tr:hover > td {
  background-color: transparent !important;
}
::v-deep .el-table__body tr.current-row > td.el-table__cell {
  background-color: transparent;
}
::v-deep .el-input__inner {
  background-color: #0e1428;
  color: #bcdcff;
  border: 1px solid #4d67b7;
  font-size: 20px;
}
::v-deep .el-input__icon {
  font-size: 20px;
  color: #bcdcff;
}

.tag-style {
  padding: 0.1rem 0.2rem;
  margin: 0.1rem;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
}
.el-table .cell {
  white-space: nowrap;
  line-height: 23px;
  padding-right: 5px;
}
</style>

<style>
.el-date-picker {
  width: 260px;
}
.el-date-picker .el-picker-panel__content {
  width: 230px;
}
</style>
