<template>
  <div>
    <div class="left-top">
      <div class="module-title">
        <i class="iconfont">&#xe78f;</i>
        <span>康复资源</span>
      </div>
      <div class="centerBottom">
        <div class="detail-list">
          <div
            class="detail-list-item"
            v-for="(item, index) in detailsList"
            :key="index"
          >
            <div class="detail-item-text">
              <h3>{{ item.title }}</h3>
              <span>{{ item.number }}</span>
              <span class="unit">{{ item.unit }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      detailsList: [
        {
          title: "三甲医院",
          number: "52",
          unit: "家",
        },
        {
          title: "体检机构",
          number: "378",
          unit: "家",
        },
        {
          title: "康复机构",
          number: 10,
          unit: "家",
        },
        {
          title: "康复医师",
          number: "50",
          unit: "名",
        },
        {
          title: "康复治疗师",
          number: 530,
          unit: "名",
        },
        {
          title: "康复护理师",
          number: "700",
          unit: "名",
        },
      ],
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.leftTop {
  position: relative;
  height: 23rem;
  width: 100%;
}
.module-title {
  padding: 18px 10px 0 10px;
  color: #bcdcff;
  font-size: 1rem;
  font-weight: bold;
  .iconfont {
    font-size: 0.875rem;
    margin-right: 0.625rem;
    color: #89e5ff;
    font-weight: 400;
  }
}
.left_dang_source {
  width: 27.675rem;
  height: 14.5rem;
}
.left_today {
  width: 27.675rem;
  height: 12rem;
}
.left_dang {
  width: 27.675rem;
  height: 11rem;
}
.user-data {
  width: 28.375rem;
  height: 34.375rem;
}

.item-box {
  & > div {
    padding-right: 5px;
  }
  font-size: 10px;
  float: right;
  position: relative;
  width: 32%;
  color: #d3d6dd;
  .dv-digital-flop {
    width: 100%;
    height: 30px;
  }
  // 金币
  .coin {
    position: relative;
    top: 6px;
    font-size: 20px;
    color: #ffc107;
  }
  .colorYellow {
    color: yellowgreen;
  }
  p {
    text-align: center;
  }
}

.centerBottom {
  padding: 0.2rem 0.7rem;
  .detail-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
    justify-content: space-around;
    width: 100%;
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 4.75rem;
      width: 32%;
      padding: 0 0.2rem;
      margin-bottom: 0.4rem;
      margin-top: 0.2rem;
      border-radius: 5px;
      border: 1px solid #343f4b;
      background-color: rgba(19, 25, 47, 0.8);
      img {
        width: 2.5rem;
        height: 2.5rem;
      }
      .detail-item-text {
        margin-left: 0.225rem;
        h3 {
          color: #bcdcff;
          font-size: 0.1rem;
          margin-bottom: 0.3rem;
        }
        span {
          font-weight: 500px;
          font-size: 0.1rem;
          font-weight: bolder;
          background: linear-gradient(to bottom, #fff, #4db6e5);
          color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
        }
        .unit {
          font-size: 0.1rem;
          margin-left: 0.225rem;
        }
      }
    }
  }
}
</style>
